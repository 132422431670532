@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.cdnfonts.com/css/bruno-ace');
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    background-color: black;
    color: white;
}